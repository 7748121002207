/// <reference path="./custom-window.d.ts" />
import {ActiveSelection, Canvas, FabricImage, FabricObject, StaticCanvas} from 'fabric';
import * as THREE from 'three';
import {animateCamera, getIntersectedConstruction, getIntersectedObjects, getMousePosition} from "./floorplan/utils";
import {activateButton, inactivateButton, setInputText, swallow, updateRoot} from "./Utils";
import {createDoor, isDoor, manipulateDoor, removeDoor, showDoorHandle} from "./floorplan/doors";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {
  createCamera, hideCameraHandle,
  hideCameraTop,
  isCamera,
  manipulateCamera, showCameraHandle,
  showCameraTop
} from "./floorplan/cameras";
import {Camera, Construction, Context, Door, Furniture, Meters, Meters2, State, Wall, Window} from "./floorplan/types";
import {
  createWall, finishWall, hideWallHandle,
  hideWallTop,
  isWall,
  manipulateWall,
  removeWall, showWallHandle,
  showWallTop
} from "./floorplan/walls";
import {createWindow, isWindow, manipulateWindow, removeWindow, showWindowHandle} from "./floorplan/windows";
import {
  createFurniture, hideFurnitureHandle,
  hideFurnitureTop,
  isFurniture, manipulateFurniture,
  removeFurniture, showFurnitureHandle,
  showFurnitureTop
} from "./floorplan/furnitures";

window.PLFloorPlanEditorInstall = async function (id, project, reference, drawing, result, show, separator) {
  console.log('Floor Plan Editor install, project=' + project + ', reference=' + reference + ', drawing=' + drawing + ', result=' + result + ', show=' + show);

  const root = document.getElementById(id + separator + 'root') as HTMLElement;
  const referenceRoot = document.getElementById(id + separator + 'reference-root');
  const drawingRoot = document.getElementById(id + separator + 'drawing-root');
  const resultRoot = document.getElementById(id + separator + 'result-root');
  const referenceCanvas = new Canvas(id + separator + 'reference');
  //const drawingCanvas = new Canvas(id + separator + 'drawing');
  const resultElement = document.getElementById(id + separator + 'result') as HTMLImageElement;

  const aspect = root.clientWidth / root.clientHeight;
  const cameraSize = 50;

  // State
  let state: State = {
    tool: 'selection',
    show: show,
    isSnapping: true,
    walls: [],
    furnitures: [],
    cameras: [],
    gridSize: new Meters(0.5),
    handleSize: 1,
    wallHeight: new Meters(3),
    wallThickness: Meters.fromCentimeters(15),
    doorHeight: new Meters(2),
    doorWidth: new Meters(0.8),
    windowHeight: new Meters(1.5),
    windowWidth: new Meters(1.5),
    scale: 5,
  }

  // Cameras
  const orthographicCamera = new THREE.OrthographicCamera(-cameraSize, cameraSize, cameraSize, -cameraSize, 1, 1000);
  const perspectiveCamera = new THREE.PerspectiveCamera(75, aspect, 1, 1000);

  // Floor & ceiling
  const geometry = new THREE.PlaneGeometry(1, 1); // Temporary size, will resize
  const floorMaterial = new THREE.MeshStandardMaterial({color: 0x808080, roughness: 0.5, metalness: 0.0});
  const floor = new THREE.Mesh(geometry, floorMaterial);
  floor.position.set(0, 0, 0);
  floor.visible = false;
  floor.receiveShadow = true;
  floor.castShadow = false;
  const ceilingMaterial = new THREE.MeshBasicMaterial({color: 0xFFFFFF, side: THREE.DoubleSide});
  const ceiling = new THREE.Mesh(geometry, ceilingMaterial);
  ceiling.visible = false;
  ceiling.position.set(0, 0, state.wallHeight.toWorld(state));

  // Context
  const context: Context = {
    container: document.getElementById(id + separator + 'container') as HTMLElement,
    scene: new THREE.Scene(),
    orthographicCamera: orthographicCamera,
    perspectiveCamera: perspectiveCamera,
    activeCamera: orthographicCamera,
    renderer: new THREE.WebGLRenderer({antialias: true}),
    raycaster: new THREE.Raycaster(),
    directionalLight: new THREE.DirectionalLight(0xFFFFFF, 1), // White light with intensity
    floor: floor,
    ceiling: ceiling,
    objectLoader: new GLTFLoader(),
    textureLoader: new THREE.TextureLoader(),

    panId(): string { return id + separator + 'zoom' + separator + 'pan' },
    selectionId(): string { return id + separator + 'tools' + separator + 'selection' },
    wallsSingleId(): string { return id + separator + 'tools' + separator + 'walls_single' },
    wallsContinuousId(): string { return id + separator + 'tools' + separator + 'walls_continuous' },
    lengthId(): string { return id + separator + 'inspector' + separator + 'length' },
}

// Floor & ceiling
// context.scene.add(context.ceiling);
context.scene.add(context.floor);

// Renderer & camera
drawingRoot?.appendChild(context.renderer.domElement);
context.renderer.shadowMap.enabled = true;
context.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
context.orthographicCamera.position.set(0, 0, 50);
context.orthographicCamera.lookAt(0, 0, 0);
context.orthographicCamera.up.set(0, 1, 0);
context.perspectiveCamera.position.set(0, -50, 10);
context.perspectiveCamera.lookAt(0, 0, 10);
context.perspectiveCamera.up.set(0, 1, 0);

// Zooming and panning
let isMouseDown = false;
let zoomInitial = 0.5;
let zoomLevel = zoomInitial;
let moveX = 0;
let moveY = 0;
let previousX = 0;
let previousY = 0;
let copiedObject: FabricObject | undefined = undefined;

// Resizing
function resize(root: HTMLElement) {
  const newWidth = root.clientWidth;
  const newHeight = root.clientHeight;
  const aspect = newWidth / newHeight;
  if (aspect >= 1) { // wider than tall
    context.orthographicCamera.left = -cameraSize * aspect;
    context.orthographicCamera.right = cameraSize * aspect;
    context.orthographicCamera.top = cameraSize;
    context.orthographicCamera.bottom = -cameraSize;
  } else { // taller than wide
    context.orthographicCamera.left = -cameraSize;
    context.orthographicCamera.right = cameraSize;
    context.orthographicCamera.top = cameraSize / aspect;
    context.orthographicCamera.bottom = -cameraSize / aspect;
  }
  context.orthographicCamera.updateProjectionMatrix();
  context.perspectiveCamera.aspect = aspect;
  context.perspectiveCamera.updateProjectionMatrix();
  context.renderer?.setSize(newWidth, newHeight);

  const distance = context.perspectiveCamera.position.length(); // Distance from the origin
  const height = 2 * Math.tan((context.perspectiveCamera.fov * Math.PI / 180) / 2) * distance;
  const width = height * aspect;
  context.floor.scale.set(width, height, 1);
  context.ceiling.scale.set(width, height, 1);

  // Grid
  if (context.grid) context.scene.remove(context.grid);
  const area = 200; // Needs to be an even number larger than the visible area (Right now 133 units)
  context.grid = new THREE.GridHelper(200, 200 / state.gridSize.meters / state.scale, 0x0000ff, 0x808080);
  context.grid.rotation.x = Math.PI / 2;
  context.scene.add(context.grid);
}

resize(root);

// Images & Data
let referenceImage: [string, FabricObject] | undefined = undefined;
let drawingData: [string, string] | undefined = undefined;
let resultImage: [string, string] | undefined = undefined;

// Lights
const ambientLight = new THREE.AmbientLight(0x404040, 1); // Soft white light
context.scene.add(ambientLight);
const hemisphereLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 1); // Sky color and ground color
context.scene.add(hemisphereLight);
const target = new THREE.Object3D();
target.position.set(0, 0, 10);
context.scene.add(target);
context.directionalLight.target = target;
context.directionalLight.intensity = 2;
context.directionalLight.color.setHex(0xFFFFFF);
context.directionalLight.castShadow = true;
context.directionalLight.shadow.mapSize.width = 1024;
context.directionalLight.shadow.mapSize.height = 1024;
context.directionalLight.position.set(0, -50, 20);
context.scene.add(context.directionalLight);

// Cameras
const center = Meters2.fromWorld(state, context.perspectiveCamera.position.x, context.perspectiveCamera.position.y);
createCamera(context, state, center);

startTool(context, state, 'selection');
updateRoot(root, zoomLevel, moveX, moveY, resize);

setupInteractiveControls();
setupKeyboardControls();
referenceImage = await setReferenceImage(referenceCanvas, referenceImage, reference);
//     drawingData = await setDrawingData(drawingCanvas, drawingData, drawing);
resultImage = await setResultElement(resultElement, resultImage, result);
updateDrawing(context, state, referenceRoot, drawingRoot, referenceCanvas, resultRoot);

function animate() {
  requestAnimationFrame(animate);
  context.renderer.render(context.scene, context.activeCamera);
}

animate(); // Start the rendering loop

window.PLFloorPlanEditorUpdate = async function (id, reference, drawing, result, show) {
  console.log('Floor Plan Editor update, reference=' + reference + ', drawing=' + drawing + ', result=' + result + ', show=' + show);
  referenceImage = await setReferenceImage(referenceCanvas, referenceImage, reference);
  // drawingData = await setDrawingData(drawingCanvas, drawingData, drawing);
  resultImage = await setResultElement(resultElement, resultImage, result);
  state.show = show;
  updateDrawing(context, state, referenceRoot, drawingRoot, referenceCanvas, resultRoot);
};

window.PLFloorPlanEditorToggleDebug = async function (event) {
  if (context.orthographicCamera.position.z === 50) {
    const target = new THREE.Vector3(0, -50, 0);
    animateCamera(context, target, 2000);
  } else {
    const target = new THREE.Vector3(0, 0, 50);
    animateCamera(context, target, 2000);
  }
  context.container?.focus(); // Always focus container to fix event handlers
};

window.PLFloorPlanEditorToggleTool = async function (event, tool) {
  if (state.tool !== tool) {
    state.tool = startTool(context, state, tool);
  } else {
    state.tool = stopTool(context, state);
  }
  context.container?.focus(); // Always focus container to fix event handlers
};

window.PLFloorPlanEditorAddConstruction = async function (event, type) {
  const selectedWall = isWall(state.selectedConstruction);
  if (type === 'door' && selectedWall) {
    createDoor(context, state, selectedWall);
  } else if (type === 'window' && selectedWall) {
    createWindow(context, state, selectedWall);
  }
  context.container?.focus(); // Always focus container to fix event handlers
};

window.PLFloorPlanEditorControlCamera = async function (event, type) {
  if (type === 'rotate_left' && state.show == 'camera1') {
    context.perspectiveCamera.rotation.y += 0.1;
    // context.directionalLight.rotation.y = context.perspectiveCamera.rotation.y;
  } else if (type === 'rotate_right' && state.show == 'camera1') {
    context.perspectiveCamera.rotation.y -= 0.1;
    // context.directionalLight.rotation.y = context.perspectiveCamera.rotation.y;
  } else if (type === 'zoom_in' && state.show == 'camera1') {
    context.perspectiveCamera.fov -= 1; // Decrease FOV for zooming in
    if (context.perspectiveCamera.fov < 10) context.perspectiveCamera.fov = 10; // Set a minimum FOV
    context.perspectiveCamera.updateProjectionMatrix();
  } else if (type === 'zoom_out' && state.show == 'camera1') {
    context.perspectiveCamera.fov += 1; // Increase FOV for zooming out
    if (context.perspectiveCamera.fov > 75) context.perspectiveCamera.fov = 75; // Set a maximum FOV
    context.perspectiveCamera.updateProjectionMatrix();
  }
}

window.PLFloorPlanEditorTogglePanning = function (event) {
  if (state.tool === 'pan') {
    state.tool = stopPanning(context);
  } else {
    state.tool = startPanning(context);
  }
  context.container?.focus(); // Always focus container to fix event handlers
}

window.PLFloorPlanEditorZoom = function (event, delta) {
  zoomLevel *= 0.999 ** delta;
  if (zoomLevel > 20) zoomLevel = 20;
  if (zoomLevel < 0.01) zoomLevel = 0.01;
  updateRoot(root, zoomLevel, moveX, moveY, resize);
  context.container?.focus(); // Always focus container to fix event handlers
};

window.PLFloorPlanEditorZoomReset = function (event) {
  zoomLevel = zoomInitial;
  updateRoot(root, zoomLevel, moveX, moveY, resize);
  context.container?.focus(); // Always focus container to fix event handlers
};

window.PLFloorPlanEditorSave = function (event) {
  /*    const jsonData = drawingCanvas.toJSON();
      const jsonString = JSON.stringify(jsonData);

      const formData = new FormData();
      const jsonBlob = new Blob([jsonString], {type: 'application/json'});
      formData.append('file', jsonBlob, 'canvas.json');
      formData.append('type', 'fabric');
      formData.append('project', project);
      if (drawingData) {
        formData.append('asset', drawingData[0]);
      }

      fetch('/floor-plan-editor-upload-floor-plan', {
        method: 'POST',
        body: formData
      }).then(response => {
        if (response.ok) {
          window.htmx.trigger(`#floor-plan-editor-refresh-${id}`, 'click');
        } else {
          console.error('Failed to upload the canvas JSON:', response.statusText);
        }
      }).catch(error => {
        console.error('Error during fetch:', error);
      });*/
  context.container?.focus(); // Always focus container to fix event handlers
};

window.PLFloorPlanEditorGenerate = function (event) {
  const canvas = context.renderer.domElement;
  context.renderer.render(context.scene, context.activeCamera);
  canvas.toBlob(function (blob) {
    if (blob) {
      const formData = new FormData();
      formData.append('file', blob, 'canvas_image.png');
      formData.append('type', 'camera');
      formData.append('project', project);
      fetch('/floor-plan-editor-upload-camera', {
        method: 'POST',
        body: formData
      }).then(r => {
        window.htmx.trigger('#floor-plan-editor-generate-' + id, 'click');
      });
    }
  }, 'image/png');
  context.container?.focus(); // Always focus container to fix event handlers
}

function setupKeyboardControls() {
  // Panning & Walls
  context.container?.addEventListener('keydown', function (e) {
    const selectedWall = isWall(state.selectedConstruction);
    const selectedDoor = isDoor(state.selectedConstruction);
    const selectedWindow = isWindow(state.selectedConstruction);
    const selectedFurniture = isFurniture(state.selectedConstruction);
    const selectedCamera = isCamera(state.selectedConstruction);
    if (e.code === 'Escape' && (state.tool === 'walls_single' || state.tool === 'walls_continuous')) {
      state.tool = stopTool(context, state);
      swallow(e);
    } else if (e.code === 'Space') {
      state.tool = startPanning(context);
      swallow(e);
    } else if (e.code === 'Backspace' && selectedWall) {
      removeWall(context, state, selectedWall);
      swallow(e);
    } else if (e.code === 'Backspace' && selectedDoor) {
      removeDoor(context, selectedDoor);
      swallow(e);
    } else if (e.code === 'Backspace' && selectedWindow) {
      removeWindow(context, selectedWindow);
      swallow(e);
    } else if (e.code === 'Backspace' && selectedFurniture) {
      removeFurniture(context, state, selectedFurniture);
      swallow(e);
    } else if (e.code === 'KeyW') {
      window.PLFloorPlanEditorToggleTool(e, 'walls_single');
      swallow(e);
    } else if (e.code === 'KeyE') {
      window.PLFloorPlanEditorToggleTool(e, 'walls_continuous');
      swallow(e);
    } else if (e.code === 'KeyD') {
      window.PLFloorPlanEditorAddConstruction(e, 'door');
      swallow(e);
    } else if (e.code === 'KeyF') {
      window.PLFloorPlanEditorAddConstruction(e, 'window');
      swallow(e);
    } else if (e.code === 'Digit1' && state.lastMouse) {
      createFurniture(context, state, state.lastMouse, 1);
      swallow(e);
    } else if (e.code === 'Digit2' && state.lastMouse) {
      createFurniture(context, state, state.lastMouse, 2);
      swallow(e);
    } else if (e.code === 'ArrowLeft' && state.show == 'camera1') {
      window.PLFloorPlanEditorControlCamera(e, 'rotate_left');
      swallow(e);
    } else if (e.code === 'ArrowRight' && state.show == 'camera1') {
      window.PLFloorPlanEditorControlCamera(e, 'rotate_right');
      swallow(e);
    } else if (e.code === 'ArrowUp' && state.show == 'camera1') {
      window.PLFloorPlanEditorControlCamera(e, 'zoom_in');
      swallow(e);
    } else if (e.code === 'ArrowDown' && state.show == 'camera1') {
      window.PLFloorPlanEditorControlCamera(e, 'zoom_out');
      swallow(e);
    }
  }, {capture: true});
  context.container?.addEventListener('keyup', function (e) {
    if (e.code === 'Space') {
      state.tool = stopPanning(context);
      swallow(e);
    }
  });

  // Copy and Paste
  /*    container?.addEventListener('keydown', async function (e) {
        if (e.code === 'KeyC' && (e.ctrlKey || e.metaKey)) { // Clone on ctrl+c
          copiedObject = await copyObject(drawingCanvas);
          swallow(e);
        } else if (e.code === 'KeyV' && (e.ctrlKey || e.metaKey)) { // Paste on ctrl+v
          pasteObject(drawingCanvas, copiedObject);
          swallow(e);
        } else if (e.code === 'KeyD' && (e.ctrlKey || e.metaKey)) { // Duplicate on ctrl+d
          pasteObject(drawingCanvas, await copyObject(drawingCanvas));
          swallow(e);
        }
      }); */

  // Keyboard movement
  /*    container?.addEventListener('keydown', function (e) {
        // set the step size for each arrow key press
        let step = 1;
        if (e.shiftKey) { // if shift key is down then increase step
          step = 20;
        }

        // get the active object - the one you want to move
        const activeObject = drawingCanvas.getActiveObject();

        if (!activeObject) return; // if no active object, do nothing

        switch (e.code) {
          case 'ArrowLeft': // left arrow key
            activeObject.left -= step;
            break;
          case 'ArrowUp': // up arrow key
            activeObject.top -= step;
            break;
          case 'ArrowRight': // right arrow key
            activeObject.left += step;
            break;
          case 'ArrowDown': // down arrow key
            activeObject.top += step;
            break;
        }

        activeObject.setCoords(); // necessary to keep track of object after moving it
        drawingCanvas.renderAll(); // re-render the canvas to see the result
      });*/
}

function setupInteractiveControls() {
  // Rotation snapping with shift
  /*drawingCanvas.on('object:rotating', function (opt) {
    if (opt.e.shiftKey === true) { // if shift key is down then snap rotation
      opt.target.snapAngle = 45;
    } else {
      opt.target.snapAngle = undefined;
    }
  });*/

  // Drawing
  drawingRoot?.addEventListener('mousedown', function (event) {
    state = onMouseDown(context, state, event);
  });

  drawingRoot?.addEventListener('mousemove', function (event) {
    state = onMouseMove(context, state, event);
  });

  drawingRoot?.addEventListener('mouseup', function (event) {
    state = onMouseUp(context, state, event);
  });

  // Zooming
  context.container.addEventListener('wheel', function (e) {
    if (e.ctrlKey || e.metaKey) {
      window.PLFloorPlanEditorZoom(e, e.deltaY);
    }
  });

  context.container.onmousedown = function (e) {
    if (state.tool === 'pan') {
      isMouseDown = true;
      previousX = e.clientX;
      previousY = e.clientY;
      swallow(e);
    }
  };
  context.container.onmouseup = function () {
    isMouseDown = false;
  };
  context.container.onmousemove = function (e) {
    if (state.tool === 'pan' && isMouseDown) {
      moveX += (e.clientX - previousX) / zoomLevel;
      moveY += (e.clientY - previousY) / zoomLevel;
      previousX = e.clientX;
      previousY = e.clientY;
      updateRoot(root, zoomLevel, moveX, moveY, resize);
      swallow(e);
    }
  };
}
}
;

async function setReferenceImage(referenceCanvas: Canvas, current: [string, FabricObject] | undefined, image: [string, string] | undefined): Promise<[string, FabricObject] | undefined> {
  const [id1] = current ?? [];
  const [id2] = image ?? [];
  if (id1 !== id2) {
    if (current) {
      referenceCanvas.remove(current[1]);
    }
    if (image && image[1] && image[1] !== '') {
      const img = await FabricImage.fromURL(image[1], {'crossOrigin': 'anonymous'});
      img.scaleToWidth(referenceCanvas.width);
      img.scaleToHeight(referenceCanvas.height);
      referenceCanvas.add(img);
      return [image[0], img];
    } else {
      return undefined;
    }
  } else {
    return current;
  }
}

async function loadJSONFromURL(url: string): Promise<any> {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Network response was not ok ' + response.statusText);
  }
  return response.json();
}

async function setDrawingData(drawingCanvas: Canvas, current: [string, string] | undefined, data: [string, string] | undefined): Promise<[string, string] | undefined> {
  const [id1] = current ?? [];
  const [id2] = data ?? [];
  if (id1 !== id2) {
    if (data && data[1] && data[1] !== '') {
      const jsonData = await loadJSONFromURL(data[1]);
      await drawingCanvas.loadFromJSON(jsonData);
      return data;
    } else {
      return undefined;
    }
  } else {
    return current;
  }
}

async function setResultElement(resultElement: HTMLImageElement, current: [string, string] | undefined, image: [string, string] | undefined): Promise<[string, string] | undefined> {
  const [id1] = current ?? [];
  const [id2] = image ?? [];
  if (id1 !== id2) {
    if (current) {
      resultElement.src = "";
    }
    if (image && image[1] && image[1] !== '') {
      resultElement.src = image[1];
      return image;
    } else {
      return undefined;
    }
  } else {
    return current;
  }
}

function showAllTops(context: Context, state: State) {
  state.walls.forEach(it => showWallTop(context, it));
  state.furnitures.forEach(it => showFurnitureTop(context, it));
  state.cameras.forEach(it => showCameraTop(context, it));
}

function hideAllTops(context: Context, state: State) {
  state.walls.forEach(it => hideWallTop(context, it));
  state.furnitures.forEach(it => hideFurnitureTop(context, it));
  state.cameras.forEach(it => hideCameraTop(context, it));
}

function hideAllHandles(context: Context, state: State) {
  state.walls.forEach(it => hideWallHandle(context, it));
  state.furnitures.forEach(it => hideFurnitureHandle(context, it));
  state.cameras.forEach(it => hideCameraHandle(context, it));
}

function onMouseDown(context: Context, state: State, event: MouseEvent): State {
  const selectedWall = isWall(state.selectedConstruction);
  state.lastMouse = getMousePosition(context, state, event);

  if (state.tool === 'selection') {
    swallow(event);
    // Check intersection with walls, doors or handles
    let intersect = getIntersectedConstruction(context, event, context.scene.children);
    const wall = intersect?.object?.userData?.isWall ? intersect?.object.userData.construction as Wall : undefined;
    const door = intersect?.object?.userData?.isDoor ? intersect?.object.userData.construction as Door : undefined;
    const window = intersect?.object?.userData?.isWindow ? intersect?.object.userData.construction as Window : undefined;
    const furniture = intersect?.object?.userData?.isFurniture ? intersect?.object.userData.construction as Furniture : undefined;
    const camera = intersect?.object?.userData?.isCamera ? intersect?.object.userData.construction as Camera : undefined;

    if (wall) {
      if (intersect!.object === wall.start) {
        state.isManipulatingConstruction = 'start';
      } else if (intersect!.object === wall.end) {
        state.isManipulatingConstruction = 'end';
      } else {
        state.isManipulatingConstruction = 'move';
      }
      selectConstruction(context, state, wall);
    } else if (door) {
      state.isManipulatingConstruction = 'move';
      selectConstruction(context, state, door);
    } else if (window) {
      if (intersect!.object === window.start) {
        state.isManipulatingConstruction = 'start';
      } else if (intersect!.object === window.end) {
        state.isManipulatingConstruction = 'end';
      } else {
        state.isManipulatingConstruction = 'move';
      }
      selectConstruction(context, state, window);
    } else if (furniture) {
      if (intersect!.object === furniture.start) {
        state.isManipulatingConstruction = 'start';
      } else if (intersect!.object === furniture.end) {
        state.isManipulatingConstruction = 'end';
      } else {
        state.isManipulatingConstruction = 'move';
      }
      selectConstruction(context, state, furniture);
    } else if (camera) {
      state.isManipulatingConstruction = 'move';
      selectConstruction(context, state, camera);
    } else {
      state.isManipulatingConstruction = undefined;
      selectConstruction(context, state, undefined);
    }
  } else if ((state.tool === 'walls_single' || state.tool === 'walls_continuous') && state.isManipulatingConstruction == 'track' && selectedWall) {
    swallow(event);
    // Second click: finish the current wall and start a new one
    finishWall(context, state, selectedWall);

    if (state.tool === 'walls_continuous') {
      const newStartPoint = new Meters2(selectedWall.endX[1], selectedWall.endY[1]);
      state.isManipulatingConstruction = 'track';
      state.unfinishedWall = createWall(context, state, newStartPoint, newStartPoint)
      selectConstruction(context, state, state.unfinishedWall);
    } else {
      state.isManipulatingConstruction = undefined;
      selectConstruction(context, state, selectedWall);
      state.unfinishedWall = undefined;
    }
  } else if (state.tool === 'walls_single' || state.tool === 'walls_continuous') {
    swallow(event);
    // First click: start a new wall
    state.unfinishedWall = createWall(context, state, state.lastMouse, state.lastMouse);
    state.isManipulatingConstruction = 'track';
    selectConstruction(context, state, state.unfinishedWall);
  }

  return state;
}

function onMouseMove(context: Context, state: State, event: MouseEvent): State {
  const selectedWall = isWall(state.selectedConstruction);
  const selectedDoor = isDoor(state.selectedConstruction);
  const selectedWindow = isWindow(state.selectedConstruction);
  const selectedFurniture = isFurniture(state.selectedConstruction);
  const selectedCamera = isCamera(state.selectedConstruction);
  if (state.isManipulatingConstruction && selectedWall) {
    swallow(event);
    manipulateWall(context, state, event, false, selectedWall);
    inspectConstruction(context, state);
  } else if (state.isManipulatingConstruction && selectedDoor) {
    swallow(event);
    manipulateDoor(context, state, event, false, selectedDoor);
  } else if (state.isManipulatingConstruction && selectedWindow) {
    swallow(event);
    manipulateWindow(context, state, event, false, selectedWindow);
  } else if (state.isManipulatingConstruction && selectedFurniture) {
    swallow(event);
    manipulateFurniture(context, state, event, false, selectedFurniture);
  } else if (state.isManipulatingConstruction && selectedCamera) {
    swallow(event);
    manipulateCamera(context, state, event, false, selectedCamera);
  }
  return state;
}

function onMouseUp(context: Context, state: State, event: MouseEvent): State {
  const selectedWall = isWall(state.selectedConstruction);
  const selectedDoor = isDoor(state.selectedConstruction);
  const selectedWindow = isWindow(state.selectedConstruction);
  const selectedFurniture = isFurniture(state.selectedConstruction);
  const selectedCamera = isCamera(state.selectedConstruction);
  if (state.isManipulatingConstruction && selectedWall) {
    swallow(event);
    manipulateWall(context, state, event, true, selectedWall);
    state.isManipulatingConstruction = state.isManipulatingConstruction === 'track' ? 'track' : undefined;
  } else if (state.isManipulatingConstruction && selectedDoor) {
    swallow(event);
    manipulateDoor(context, state, event, true, selectedDoor);
    state.isManipulatingConstruction = undefined;
  } else if (state.isManipulatingConstruction && selectedWindow) {
    swallow(event);
    manipulateWindow(context, state, event, true, selectedWindow);
    state.isManipulatingConstruction = undefined;
  } else if (state.isManipulatingConstruction && selectedFurniture) {
    swallow(event);
    manipulateFurniture(context, state, event, true, selectedFurniture);
    state.isManipulatingConstruction = undefined;
  } else if (state.isManipulatingConstruction && selectedCamera) {
    swallow(event);
    manipulateCamera(context, state, event, true, selectedCamera);
    state.isManipulatingConstruction = undefined;
  } else if (state.isManipulatingConstruction) {
    swallow(event);
    state.isManipulatingConstruction = undefined;
  }

  return state;
}

async function copyObject(drawingCanvas: Canvas): Promise<FabricObject | undefined> {
  return drawingCanvas.getActiveObject()?.clone();
}

function pasteObject(drawingCanvas: Canvas, copiedObject: FabricObject | undefined) {
  copiedObject?.clone()
    .then((obj) => {
      drawingCanvas.discardActiveObject();
      obj.set({
        left: obj.left + 10,
        top: obj.top + 10,
      });
      if (obj instanceof ActiveSelection) {
        obj.canvas = drawingCanvas;
        obj.forEachObject(function (obj) {
          drawingCanvas.add(obj);
        });
        obj.setCoords();
      } else {
        drawingCanvas.add(obj);
      }
      drawingCanvas.setActiveObject(obj);
      drawingCanvas.requestRenderAll();
    })
}

function selectConstruction(context: Context, state: State, construction: Construction | undefined) {
  hideAllHandles(context, state);
  state.selectedConstruction = construction

  const selectedWall = isWall(state.selectedConstruction);
  const selectedDoor = isDoor(state.selectedConstruction);
  const selectedWindow = isWindow(state.selectedConstruction);
  const selectedFurniture = isFurniture(state.selectedConstruction);
  const selectedCamera = isCamera(state.selectedConstruction);

  if (selectedWall) showWallHandle(context, selectedWall);
  if (selectedDoor) showDoorHandle(context, selectedDoor);
  if (selectedWindow) showWindowHandle(context, selectedWindow);
  if (selectedFurniture) showFurnitureHandle(context, selectedFurniture);
  if (selectedCamera) showCameraHandle(context, selectedCamera);

  inspectConstruction(context, state);
}

function inspectConstruction(context: Context, state: State) {
  const selectedWall = isWall(state.selectedConstruction);
  if (selectedWall) setInputText(context.lengthId(), selectedWall.length.meters.toString());
}

function startTool(context: Context, state: State, tool: Tool): Tool {
  stopTool(context, state);

  if (tool === 'walls_single') {
    context.container.style.cursor = 'crosshair';
    activateButton(context.wallsSingleId());
    inactivateButton(context.selectionId());
    inactivateButton(context.wallsContinuousId());
    return 'walls_single';
  } else if (tool === 'walls_continuous') {
    context.container.style.cursor = 'crosshair';
    activateButton(context.wallsContinuousId());
    inactivateButton(context.selectionId());
    inactivateButton(context.wallsSingleId());
    return 'walls_continuous';
  } else {
    context.container.style.cursor = 'default';
    activateButton(context.selectionId());
    inactivateButton(context.wallsSingleId());
    inactivateButton(context.wallsContinuousId());
    return 'selection';
  }
}

function stopTool(context: Context, state: State): Tool {
  context.container.style.cursor = 'default';

  // Remove unfinished wall
  if ((state.tool == 'walls_single' || state.tool == 'walls_continuous') && state.unfinishedWall) {
    removeWall(context, state, state.unfinishedWall);
    state.unfinishedWall = undefined;
    const lastWall = state.walls[state.walls.length - 1];
    if (lastWall) selectConstruction(context, state, lastWall);
  }
  state.isManipulatingConstruction = undefined;

  activateButton(context.selectionId());
  inactivateButton(context.wallsSingleId());
  inactivateButton(context.wallsContinuousId());
  return 'selection';
}

function startPanning(context: Context): Tool {
  activateButton(context.panId());
  context.container.style.cursor = 'grab';
  return 'pan';
}

function stopPanning(context: Context): Tool {
  inactivateButton(context.panId());
  context.container.style.cursor = 'default';
  return 'selection';
}

function showOrthographicCamera(context: Context, state: State) {
  showAllTops(context, state);
  if (context.grid) context.grid.visible = true;
  context.floor.visible = false;
  context.ceiling.visible = false;
  context.activeCamera = context.orthographicCamera;
}

function showPerspectiveCamera(context: Context, state: State) {
  hideAllTops(context, state);
  hideAllHandles(context, state);
  if (context.grid) context.grid.visible = false;
  context.floor.visible = true;
  context.ceiling.visible = true;
  context.activeCamera = context.perspectiveCamera;
}

function updateDrawing(context: Context, state: State, referenceRoot: HTMLElement | null, drawingRoot: HTMLElement | null, referenceCanvas: Canvas, resultRoot: HTMLElement | null) {
  const showReference = state.show === 'reference' || state.show === 'floor_plan_with_reference'
  const showDrawing = state.show === 'floor_plan' || state.show === 'floor_plan_with_reference' || state.show === 'camera1'
  const showCamera1 = state.show === 'camera1'
  const showResult1 = state.show === 'result1'

  if (referenceRoot) {
    referenceRoot.style.display = showReference ? 'block' : 'none';
  }
  if (drawingRoot) {
    drawingRoot.style.display = showDrawing ? 'block' : 'none';
  }
  if (resultRoot) {
    resultRoot.style.display = showResult1 ? 'block' : 'none';
  }

  if (showDrawing && showReference) {
    context.renderer.setClearColor(new THREE.Color(0xffffff), 0.5);
  } else if (showDrawing) {
    context.renderer.setClearColor(new THREE.Color(0xffffff), 1);
  }
  if (showReference) {
    referenceCanvas.backgroundColor = 'white';
  }

  if (showCamera1) {
    showPerspectiveCamera(context, state);
  } else {
    showOrthographicCamera(context, state);
  }

  context.container?.focus(); // Always focus container to fix event handlers
}

console.log('PLFloorPlanEditor2 has been installed');
