import * as THREE from "three";
import {v4} from "uuid";
import {Construction, Context, Furniture, Meters, Meters2, State} from "./types";
import {disposeAll, getMousePosition} from "./utils";

export async function createFurniture(context: Context, state: State, center: Meters2, type: number): Promise<Furniture> {
  const height = new Meters(2)
  const thickness = state.wallThickness;

  const furniture: Furniture = {
    type: 'furniture',
    id: v4(),
    startX: center.x.subtract(height),
    startY: center.y,
    endX: center.x.add(height),
    endY: center.y,
    paddingX: 0,
    paddingY: 0,
    height: height,
    angle: 0,
    scale: 0,
    thickness: thickness,
  };

  const direction = Meters2.fromDirection(furniture.startX, furniture.endX, furniture.startY, furniture.endY);
  const positionX = (furniture.startX.toWorld(state) + furniture.endX.toWorld(state) + (2 * furniture.paddingX)) / 2;
  const positionY = (furniture.startY.toWorld(state) + furniture.endY.toWorld(state) + (2 * furniture.paddingY)) / 2;
  furniture.angle = direction.angle;
  furniture.scale = 1; //direction.length.toWorld(state);

  const lookup: { [key: number]: string } = {
    1: "sofa",
    2: "desk",
  };

  // Create a model using multiple box geometries
  furniture.side = await loadModel(context, state, furniture, lookup[type] || 'sofa');
  furniture.side[0].position.set(positionX, positionY, 0);
  furniture.side[0].rotation.z = furniture.angle;
  furniture.side[0].scale.set(state.scale, state.scale, state.scale);
  context.scene.add(furniture.side[0]);

  // Create handles for resizing
  const handleGeometry = new THREE.SphereGeometry(state.handleSize, 16, 16);
  const handleMaterial = new THREE.MeshBasicMaterial({color: 0x0000ff});

  furniture.start = new THREE.Mesh(handleGeometry, handleMaterial);
  furniture.start.position.set(furniture.startX.subtract(furniture.thickness).toWorld(state), furniture.startY.toWorld(state), furniture.height.toWorld(state));
  furniture.start.visible = false;
  furniture.start.userData.isFurniture = true;
  furniture.start.userData.construction = furniture;
  furniture.start.userData.isFurnitureCStart = true;
  context.scene.add(furniture.start);

  furniture.end = new THREE.Mesh(handleGeometry, handleMaterial);
  furniture.end.position.set(furniture.endX.add(furniture.thickness).toWorld(state), furniture.endY.toWorld(state), furniture.height.toWorld(state));
  furniture.end.visible = false;
  furniture.end.userData.isFurniture = true;
  furniture.end.userData.construction = furniture;
  furniture.end.userData.isFurnitureCEnd = true;
  context.scene.add(furniture.end);

  state.furnitures.push(furniture);

  return furniture;
}

export function updateFurniture(context: Context, state: State, furniture: Furniture) {
  const direction = Meters2.fromDirection(furniture.startX, furniture.endX, furniture.startY, furniture.endY);
  const positionX = (furniture.startX.toWorld(state) + furniture.endX.toWorld(state) + 2 * furniture.paddingX) / 2;
  const positionY = (furniture.startY.toWorld(state) + furniture.endY.toWorld(state) + 2 * furniture.paddingY) / 2;
  furniture.angle = direction.angle
  furniture.scale = 1; // direction.length.toWorld(state);

  if (furniture.side) {
    furniture.side[0].position.set(positionX, positionY, 0);
    furniture.side[0].rotation.z = furniture.angle;
    // furniture.side.scale.set(furniture.scale, furniture.scale, furniture.scale);
    context.scene.add(furniture.side[0]);
  }
  if (furniture.top) {
  }
  if (furniture.start) {
    furniture.start.position.set(furniture.startX.subtract(furniture.thickness).toWorld(state), furniture.startY.toWorld(state), furniture.height.toWorld(state));
  }
  if (furniture.end) {
    furniture.end.position.set(furniture.endX.add(furniture.thickness).toWorld(state), furniture.endY.toWorld(state), furniture.height.toWorld(state));
  }
}

export function removeFurniture(context: Context, state: State, furniture: Furniture) {
  if (furniture.side) furniture.side = disposeAll(context, furniture.side[0]);
  if (furniture.top) furniture.top = disposeAll(context, furniture.top[0]);
  if (furniture.start) furniture.start = disposeAll(context, furniture.start);
  if (furniture.end) furniture.end = disposeAll(context, furniture.end);

  state.furnitures = state.furnitures.filter(f => f.id !== furniture.id);
}

export function manipulateFurniture(context: Context, state: State, event: MouseEvent, isFinished: boolean, furniture: Furniture) {
  if (!isFinished && state.lastMouse) {
    const mouse = getMousePosition(context, state, event);

    if (state.isManipulatingConstruction === 'move') {
      const delta = mouse.subtract(state.lastMouse);

      furniture.startX = furniture.startX.add(delta.x);
      furniture.startY = furniture.startY.add(delta.y);
      furniture.endX = furniture.endX.add(delta.x);
      furniture.endY = furniture.endY.add(delta.y);

      state.lastMouse = mouse;
    } else if (state.isManipulatingConstruction === 'start') {
      furniture.startX = mouse.x;
      furniture.startY = mouse.y;
    } else if (state.isManipulatingConstruction === 'end') {
      furniture.endX = mouse.x;
      furniture.endY = mouse.y;
    }

    updateFurniture(context, state, furniture);
  }
}

export function showFurnitureTop(context: Context, furniture: Furniture) {
  if (furniture.top) furniture.top[0].visible = true;
}

export function hideFurnitureTop(context: Context, furniture: Furniture) {
  if (furniture.top) furniture.top[0].visible = false;
}

export function showFurnitureHandle(context: Context, furniture: Furniture) {
  if (furniture.start) furniture.start.visible = true;
  if (furniture.end) furniture.end.visible = true;
}

export function hideFurnitureHandle(context: Context, furniture: Furniture) {
  if (furniture.start) furniture.start.visible = false;
  if (furniture.end) furniture.end.visible = false;
}

export function isFurniture(construction: Construction | undefined): Furniture | undefined {
  if (construction?.type === 'furniture') {
    return construction as Furniture;
  }
  return undefined;
}

function createSofa(context: Context, furniture: Furniture, size: number): THREE.Object3D {
  const medium = size / 2
  const small = medium / 2
  const tiny = size * 0.2
  const micro = tiny / 2

  const sofa = new THREE.Group(); // width, height, depth, x, y, z
  sofa.add(createBox(context, furniture, size, medium, tiny, 0, 0, micro)); // Seat
  sofa.add(createBox(context, furniture, size, tiny, medium, 0, tiny + micro, small)); // Backrest
  sofa.add(createBox(context, furniture, tiny, medium, medium, medium + micro, tiny - micro, small));// Left Armrest
  sofa.add(createBox(context, furniture, tiny, medium, medium, -(medium + micro), tiny - micro, small)); // Right Armrest
  return sofa;
}

function createBox(context: Context, furniture: Furniture, width: number, height: number, depth: number, x: number, y: number, z: number) {
  const geometry = new THREE.BoxGeometry(width, height, depth);
  const material = new THREE.MeshBasicMaterial({color: 0x006400});
  const box = new THREE.Mesh(geometry, material);
  box.userData.isFurniture = true;
  box.userData.construction = furniture;
  box.userData.isFurniturePart = true;
  const wireframeMaterial = new THREE.MeshBasicMaterial({color: 0xFFFFFF, wireframe: true});
  const wireframe = new THREE.Mesh(geometry, wireframeMaterial);
  wireframe.userData.isFurniture = true;
  wireframe.userData.construction = furniture;
  wireframe.userData.isFurniturePart = true;
  const group = new THREE.Group();
  group.add(box);
  group.add(wireframe);
  group.position.set(x, y, z);
  return group;
}

async function loadModel(context: Context, state: State, furniture: Furniture, model: string): Promise<[THREE.Object3D, THREE.Mesh[], undefined]> {
  return new Promise((resolve, reject) => {
    context.objectLoader.load(
      `/assets/3d/${model}.glb`,
      function (gltf) {
        const model = gltf.scene;
        model.rotation.x = Math.PI / 2; // Rotate to top-down

        let meshes: THREE.Mesh[] = [];
        // Apply solid light gray material to each mesh in the model
        model.traverse((child: THREE.Object3D) => {
          const mesh = child as THREE.Mesh;
          if (mesh) {
            meshes.push(mesh);
            mesh.userData.isFurniture = true;
            mesh.userData.construction = furniture;
            mesh.userData.isFurniturePart = true;

            mesh.material = new THREE.MeshStandardMaterial({
              map: /*mesh.material.map ||*/ null,
              color: /*mesh.material.color ||*/ new THREE.Color(0x808080),
              roughness: 0.6,
              metalness: 0.2
            });
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });

        // Resolve the promise with the model wrapped in a group
        const group = new THREE.Group();
        group.add(model);
        resolve([group, meshes, undefined]);
      },
      undefined,
      function (error) {
        console.error('An error occurred while loading the model', error);
        reject(error);
      }
    );
  });
}
