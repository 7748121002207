import * as THREE from "three";
import {Context, Meters, Meters2, State} from "./types";

function getMouseVector(context: Context, event: MouseEvent): THREE.Vector2 {
  const rect = context.renderer.domElement.getBoundingClientRect();
  return new THREE.Vector2(
    ((event.clientX - rect.left) / rect.width) * 2 - 1,
    -((event.clientY - rect.top) / rect.height) * 2 + 1
  );
}

export function getMousePosition(context: Context, state: State, event: MouseEvent): Meters2 {
  const mouse = getMouseVector(context, event);

  // Convert mouse position to world coordinates in 2D
  const vector = new THREE.Vector3(mouse.x, mouse.y, 0).unproject(context.activeCamera);

  // Apply scaling factor to convert to meters
  return Meters2.fromWorld(state, vector.x, vector.y);
}

export function getIntersectedObjects(context: Context, event: MouseEvent, objects: THREE.Object3D[]): THREE.Intersection[] {
  const mouse = getMouseVector(context, event);
  context.raycaster.setFromCamera(mouse, context.activeCamera);
  return context.raycaster.intersectObjects(objects, true);
}

export function getIntersectedConstruction(context: Context, event: MouseEvent, objects: THREE.Object3D[]): THREE.Intersection | undefined {
  return getIntersectedObjects(context, event, objects).find(it => it?.object.userData.construction !== undefined);
}

export function lerp(x: Meters, y: Meters, t: number): Meters {
  return new Meters(THREE.MathUtils.lerp(x.meters, y.meters, t));
}

export function getOriginalX(x: number, y: number, angle: number): number {
  // Create a vector for the point
  const point = new THREE.Vector2(x, y);

  // Create a 2D rotation matrix for the inverse transformation
  const rotationMatrix = new THREE.Matrix3().set(
    Math.cos(angle), Math.sin(angle), 0,
    -Math.sin(angle), Math.cos(angle), 0,
    0, 0, 1
  );

  // Apply the inverse rotation matrix to the point
  point.applyMatrix3(rotationMatrix);

  // Return the x-coordinate after reversing the rotation
  return point.x;
}

export function snapToGrid(state: State, position: Meters2): Meters2 {
  if (state.isSnapping) {
    const size = state.gridSize.meters; // This should be half-meters (0.5 meters)
    const snappedX = Math.round(position.x.meters / size) * size;
    const snappedY = Math.round(position.y.meters / size) * size;
    return new Meters2(new Meters(snappedX), new Meters(snappedY));
  } else {
    return position;
  }
}

export function disposeAll(context: Context, group: THREE.Object3D): undefined {
  group.traverse((child) => {
    const mesh = child as THREE.Mesh;
    if (mesh) {
      if (mesh.geometry) {
        mesh.geometry.dispose();
      }
      if (mesh.material) {
        if (Array.isArray(mesh.material)) {
          mesh.material.forEach(material => {
            material.dispose();
          });
        } else {
          mesh.material.dispose();
        }
      }
    }
  });
  context.scene.remove(group);  // Remove from the scene
  return undefined;
}

export function calculateGridSize(state: State, camera: THREE.OrthographicCamera): Meters {
  const width = camera.right - camera.left;  // Width of the visible area
  const height = camera.top - camera.bottom;  // Height of the visible area
  return Meters.fromWorld(state, Math.max(width, height));
}

export function animateCamera(context: Context, targetPosition: THREE.Vector3, duration: number) {
  const startPosition = context.activeCamera.position.clone();
  const startTime = performance.now();

  function update() {
    const elapsed = performance.now() - startTime;
    const t = Math.min(elapsed / duration, 1); // Calculate progress [0, 1]

    // Linear interpolation
    context.activeCamera.position.lerpVectors(startPosition, targetPosition, t);
    context.activeCamera.lookAt(new THREE.Vector3(0, 0, 0));
    context.orthographicCamera.updateProjectionMatrix();

    if (t < 1) {
      requestAnimationFrame(update); // Continue animating
    }
  }

  requestAnimationFrame(update);
}
