
export function hardcoded(s: string) : string {
  return s;
}

export function updateRoot(root: HTMLElement | null, zoomLevel: number, moveX: number, moveY: number, additional?: (root: HTMLElement) => void ) {
  if (root) {
    root.style.transform = 'scale(' + zoomLevel + ')' + 'translate(' + moveX + 'px,' + moveY + 'px)';
    if (additional) { additional(root) }
  }
}

export function swallow(event: Event) {
  event.preventDefault();
  event.stopPropagation();
}

export function activateButton(id: string) {
  const button = document.getElementById(id);
  button?.classList.add('active');
  button?.setAttribute('aria-pressed', 'true');
}

export function inactivateButton(id: string) {
  const button = document.getElementById(id);
  button?.classList.remove('active');
  button?.setAttribute('aria-pressed', 'false');
}

export function setInputText(id: string, text: string) {
  const input = document.getElementById(id);
  if (input instanceof HTMLInputElement) {
    input.value = text;
  }
}
