import * as THREE from "three";
import {v4} from "uuid";
import {Construction, Context, State, Wall, Window} from "./types";
import {disposeAll, getMousePosition, lerp} from "./utils";
import {recreateSide} from "./walls";

export function createWindow(context: Context, state: State, wall: Wall): Window {
  const wallOffset = 0.5;
  const floorOffset = 0.25;

  const window: Window = {
    type: 'window',
    id: v4(),
    wall: wall,
    wallOffset: wallOffset,
    floorOffset: floorOffset,
    centerX: lerp(wall.startX[1], wall.endX[1], wallOffset),
    centerY: lerp(wall.startY[1], wall.endY[1], wallOffset),
    paddingX: 0,
    paddingY: 0,
    width: state.windowWidth,
    originalWidth: state.windowWidth,
    originalScale: new THREE.Vector3(1, 1, 1),
    height: state.windowHeight,
    angle: wall.angle,
    thickness: state.wallThickness,
  };

  const positionX = window.centerX.toWorld(state);
  const positionY = window.centerY.toWorld(state);
  const positionZ = THREE.MathUtils.lerp(0, window.wall.height.toWorld(state), window.floorOffset) + (window.height.toWorld(state) / 2);

  // Create window side geometry
  window.side = createSide(context, window, window.width.toWorld(state), window.height.toWorld(state), state.wallThickness.toWorld(state));
  window.side[0].position.set(positionX, positionY, positionZ);
  window.side[0].scale.set(window.originalScale.x, window.originalScale.y, window.originalScale.z);
  window.side[0].rotation.z = window.angle;
  if(window.side[2]) {
    const length = window.wall.length.toWorld(state);
    const height = window.wall.height.toWorld(state);
    const neutralX = length * window.wallOffset - length / 2;
    const neutralY = height * window.floorOffset - window.height.toWorld(state) / 2;
    window.side[2].position.set(neutralX, -0.5, neutralY);
  }
  context.scene.add(window.side[0]);

  // Create window 2D top-down representation slightly above the wall height
  window.top = createTop(window, window.width.toWorld(state), state.wallThickness.toWorld(state));
  window.top[0].position.set(window.centerX.toWorld(state), window.centerY.toWorld(state), state.wallHeight.toWorld(state) + 0.2); // slightly above the wall
  window.top[0].scale.set(window.originalScale.x, window.originalScale.y, window.originalScale.z);
  window.top[0].rotation.z = window.angle;
  context.scene.add(window.top[0]);

  /*
  // Create handles for resizing
  const handleGeometry = new THREE.SphereGeometry(state.handleSize, 16, 16);
  const handleMaterial = new THREE.MeshBasicMaterial({color: 0x0000ff});

  window.start = new THREE.Mesh(handleGeometry, handleMaterial);
  window.start.position.set(window.centerX.subtract(window.originalWidth).toWorld(state), window.centerY.toWorld(state), state.wallHeight.toWorld(state) + 0.3); // slightly above the wall
  window.start.rotation.z = window.angle;
  window.start.visible = false;
  window.start.userData.isWindow = true;
  window.start.userData.construction = window;
  window.start.userData.isWindowStart = true;
  context.scene.add(window.start);

  window.end = new THREE.Mesh(handleGeometry, handleMaterial);
  window.end.position.set(window.centerX.add(window.originalWidth).toWorld(state), window.centerY.toWorld(state), state.wallHeight.toWorld(state) + 0.3); // slightly above the wall
  window.end.rotation.z = window.angle;
  window.end.visible = false;
  window.end.userData.isWindow = true;
  window.end.userData.construction = window;
  window.end.userData.isWindowEnd = true;
  context.scene.add(window.end);
*/
  wall.constructions.push(window);
  recreateSide(context, state, wall, 2);

  return window;
}

export function updateWindow(state: State, window: Window) {
  const scalingFactor = window.width.meters / window.originalWidth.meters;

  if (window.side) {
    window.centerX = lerp(window.wall.startX[1], window.wall.endX[1], window.wallOffset);
    window.centerY = lerp(window.wall.startY[1], window.wall.endY[1], window.wallOffset);
    window.angle = window.wall.angle;

    const positionX = window.centerX.toWorld(state);
    const positionY = window.centerY.toWorld(state);
    const positionZ = THREE.MathUtils.lerp(0, window.wall.height.toWorld(state), window.floorOffset) + (window.height.toWorld(state) / 2);

    window.side[0].position.set(positionX, positionY, positionZ);
    window.side[0].scale.set(scalingFactor * window.originalScale.x, window.originalScale.y, window.originalScale.z);
    window.side[0].rotation.z = window.angle;
    if (window.side[2]) {
      const length = window.wall.length.toWorld(state);
      const neutralX = length * window.wallOffset - length / 2;
      window.side[2].position.set(neutralX, window.side[2].position.y, window.side[2].position.z);
    }
  }
  if (window.top) {
    window.top[0].position.set(window.centerX.toWorld(state), window.centerY.toWorld(state), state.wallHeight.toWorld(state) + 0.2); // slightly above the wall
    window.top[0].scale.set(scalingFactor * window.originalScale.x, window.originalScale.y, window.originalScale.z);
    window.top[0].rotation.z = window.angle;
  }
  if (window.start) {
    window.start.position.set(window.centerX.subtract(window.originalWidth).toWorld(state), window.centerY.toWorld(state), state.wallHeight.toWorld(state) + 0.3); // slightly above the wall
    window.start.rotation.z = window.angle;
  }
  if (window.end) {
    window.end.position.set(window.centerX.add(window.originalWidth).toWorld(state), window.centerY.toWorld(state), state.wallHeight.toWorld(state) + 0.3); // slightly above the wall
    window.end.rotation.z = window.angle;
  }
}

export function removeWindow(context: Context, window: Window) {
  if (window.side) window.side = disposeAll(context, window.side[0]);
  if (window.top) window.top = disposeAll(context, window.top[0]);
  if (window.start) window.start = disposeAll(context, window.start);
  if (window.end) window.end = disposeAll(context, window.end);
}

export function manipulateWindow(context: Context, state: State, event: MouseEvent, isFinished: boolean, window: Window) {
  if (!isFinished && state.lastMouse) {
    const mouse = getMousePosition(context, state, event);
    const delta = mouse.subtract(state.lastMouse);

    // Wall vector
    const wallVector = new THREE.Vector2(window.wall.endX[1].meters - window.wall.startX[1].meters, window.wall.endY[1].meters - window.wall.startY[1].meters);
    // Wall length
    const wallLength = wallVector.length();
    // Normalize wall vector
    const wallDirection = wallVector.clone().normalize();
    // Mouse delta vector
    const mouseDelta = new THREE.Vector2(delta.x.meters, delta.y.meters);
    // Project mouse delta onto wall direction
    const projectedDelta = mouseDelta.dot(wallDirection);

    if (!window.originalWidth) {
      window.originalWidth = window.width;
    }
    if (!window.originalScale) {
      window.originalScale = window.side ? window.side[0].scale.clone() : new THREE.Vector3(1, 1, 1);
    }

    if (state.isManipulatingConstruction === 'move') {
      // Calculate the change in wallOffset
      const deltaWallOffset = projectedDelta / wallLength;
      window.wallOffset += deltaWallOffset;

      // Adjust for door width
      const doorWidthAsOffset = (window.width.meters / wallLength) / 2;
      window.wallOffset = Math.max(doorWidthAsOffset, Math.min(1 - doorWidthAsOffset, window.wallOffset));
    } else if (state.isManipulatingConstruction === 'start') {
      // Increase the width by the projected delta
      window.width = window.width.subtract(projectedDelta);

      // Ensure the start of the window stays within the wall bounds
      const newWallOffsetStart = window.wallOffset - (projectedDelta / wallLength) / 2;
      if (newWallOffsetStart >= 0 && (window.wallOffset + (window.width.meters / wallLength) / 2) <= 1) {
        window.wallOffset = newWallOffsetStart;
      } else {
        // If out of bounds, revert the width change
        window.width = window.width.add(projectedDelta);
      }
    } else if (state.isManipulatingConstruction === 'end') {
      // Increase the width by the projected delta
      window.width = window.width.add(projectedDelta);

      // Ensure the end of the window stays within the wall bounds
      const newWallOffsetEnd = window.wallOffset + (projectedDelta / wallLength) / 2;
      if (newWallOffsetEnd <= 1 && (window.wallOffset - (window.width.meters / wallLength) / 2) >= 0) {
        window.wallOffset = newWallOffsetEnd;
      } else {
        // If out of bounds, revert the width change
        window.width = window.width.subtract(projectedDelta);
      }
    }

    updateWindow(state, window);
    state.lastMouse = mouse;
  } else if (isFinished) {
    recreateSide(context, state, window.wall, 2);
  }
}

export function showWindowTop(context: Context, window: Window) {
  if (window.top) window.top[0].visible = true;
}

export function hideWindowTop(context: Context, window: Window) {
  if (window.top) window.top[0].visible = false;
}

export function showWindowHandle(context: Context, window: Window) {
  if (window.start) window.start.visible = true;
  if (window.end) window.end.visible = true;
}

export function hideWindowHandle(context: Context, window: Window) {
  if (window.start) window.start.visible = false;
  if (window.end) window.end.visible = false;
}

export function isWindow(construction: Construction | undefined): Window | undefined {
  if (construction?.type === 'window') {
    return construction as Window;
  }
  return undefined;
}

function createSide(context: Context, window: Window, width: number, height: number, wallThickness: number): [THREE.Object3D, THREE.Mesh[], THREE.Mesh | undefined] {
  const frame1 = createFrame(context, window, width, height);
  const frame2 = createFrame(context, window, width, height);
  frame2.rotation.y = Math.PI;

  // Create the geometry (to subtract from the wall)
  const geometry = new THREE.BoxGeometry(width, wallThickness + 1.0, height);
  const subtract = new THREE.Mesh(geometry);

  const group = new THREE.Group();
  group.add(frame1);
  group.add(frame2);
  frame1.position.set(0, -0.1, 0);
  frame2.position.set(0, 0.1, 0);
  group.position.set(0, 0, 0);

  group.userData.isWindow = true;
  group.userData.construction = window;
  group.userData.isWindowSide = true;

  return [group, [frame1, frame2], subtract];
}

function createFrame(context: Context, window: Window, width: number, height: number) {
  const geometry = new THREE.PlaneGeometry(width, height);

  const texture = context.textureLoader.load(`/assets/3d/window.png`, () => {
    context.renderer.render(context.scene, context.activeCamera);
  });
  const material = new THREE.MeshBasicMaterial({ map: texture });

  const frame = new THREE.Mesh(geometry, material);
  frame.rotation.x = Math.PI / 2; // Rotate to top-down

  frame.userData.isWindow = true;
  frame.userData.construction = window;
  frame.userData.isWindowPart = true;

  return frame;
}

function createTop(window: Window, width: number, thickness: number): [THREE.Object3D, THREE.Mesh[]] {
  const geometry = new THREE.BoxGeometry(width, thickness, 0.5);

  const material = new THREE.MeshBasicMaterial({color: 0xFFFFFF});
  const box = new THREE.Mesh(geometry, material);
  box.userData.isWindow = true;
  box.userData.construction = window;
  box.userData.isWindowPart = true;

  const wireframeMaterial = new THREE.MeshBasicMaterial({color: 0x000000, wireframe: true});
  const wireframe = new THREE.Mesh(geometry, wireframeMaterial);
  wireframe.userData.isWindow = true;
  wireframe.userData.construction = window;
  wireframe.userData.isWindowPart = true;

  const group = new THREE.Group();
  group.add(box);
  group.add(wireframe);
  group.position.set(0, 0, 0)

  group.userData.isWindow = true;
  group.userData.construction = window;
  group.userData.isWindowTop = true;

  return [group, [box, wireframe]];
}
