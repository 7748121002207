import * as THREE from "three";
import {v4} from "uuid";
import {Camera, Construction, Context, Door, Meters, Meters2, State} from "./types";
import {disposeAll, getMousePosition} from "./utils";

export function createCamera(context: Context, state: State, center: Meters2): Camera {
  const camera: Camera = {
    type: 'camera',
    id: v4(),
    centerX: center.x,
    centerY: center.y,
    paddingX: 0,
    paddingY: 0,
    height: new Meters(0.5),
    angle: 0,
    thickness: state.wallThickness,
  };

  // Create camera side geometry
  camera.side = createSide(camera, camera.height.toWorld(state));
  camera.side[0].position.set(camera.centerX.toWorld(state), camera.centerY.toWorld(state), camera.height.divide(2).toWorld(state));
  context.scene.add(camera.side[0]);

  // Create handles for resizing
  const handleGeometry = new THREE.SphereGeometry(state.handleSize, 16, 16);
  const handleMaterial = new THREE.MeshBasicMaterial({color: 0x0000ff});
  camera.center = new THREE.Mesh(handleGeometry, handleMaterial);
  camera.center.position.set(camera.centerX.toWorld(state), camera.centerY.toWorld(state), camera.height.divide(2).toWorld(state) + 0.1);
  camera.center.visible = false;
  camera.center.userData.isDoor = true;
  camera.center.userData.construction = camera;
  camera.center.userData.isDoorCenter = true;
  context.scene.add(camera.center);

  context.perspectiveCamera.position.x = camera.centerX.toWorld(state);
  context.perspectiveCamera.position.y = camera.centerY.toWorld(state);
  context.directionalLight.position.x = camera.centerX.toWorld(state);
  context.directionalLight.position.y = camera.centerY.toWorld(state);

  state.cameras.push(camera);

  return camera;
}

export function updateCamera(context: Context, state: State, camera: Camera) {
  if (camera.side) {
    camera.side[0].position.set(camera.centerX.toWorld(state), camera.centerY.toWorld(state), camera.side[0].position.z);
  }
  if (camera.top) {
  }
  if (camera.center) {
    camera.center.position.set(camera.centerX.toWorld(state), camera.centerY.toWorld(state), camera.center.position.z);
  }
  context.perspectiveCamera.position.x = camera.centerX.toWorld(state);
  context.perspectiveCamera.position.y = camera.centerY.toWorld(state);
  context.directionalLight.position.x = camera.centerX.toWorld(state);
  context.directionalLight.position.y = camera.centerY.toWorld(state);
}

export function removeCamera(context: Context, camera: Camera) {
  if (camera.side) camera.side = disposeAll(context, camera.side[0]);
  if (camera.top) camera.top = disposeAll(context, camera.top[0]);
  if (camera.center) camera.center = disposeAll(context, camera.center);
}

export function manipulateCamera(context: Context, state: State, event: MouseEvent, isFinished: boolean, camera: Camera) {
  if (!isFinished && state.lastMouse) {
    const mouse = getMousePosition(context, state, event);

    if (state.isManipulatingConstruction === 'move') {
      const delta = mouse.subtract(state.lastMouse);

      camera.centerX = camera.centerX.add(delta.x);
      camera.centerY = camera.centerY.add(delta.y);

      updateCamera(context, state, camera);
      state.lastMouse = mouse;
    }
  }
}

export function showCameraTop(context: Context, camera: Camera) {
  if (camera.top) camera.top[0].visible = true;
}

export function hideCameraTop(context: Context, camera: Camera) {
  if (camera.top) camera.top[0].visible = false;
}

export function showCameraHandle(context: Context, camera: Camera) {
  if (camera.center) camera.center.visible = true;
}

export function hideCameraHandle(context: Context, camera: Camera) {
  if (camera.center) camera.center.visible = false;
}

export function isCamera(construction: Construction | undefined): Camera | undefined {
  if (construction?.type === 'camera') {
    return construction as Camera;
  }
  return undefined;
}

function createSide(camera: Camera, size: number): [THREE.Object3D, THREE.Mesh[], undefined] {
  const geometry = new THREE.BoxGeometry(size, size, size);
  const material = new THREE.MeshBasicMaterial({color: 0xFFD700});
  let box: THREE.Mesh = new THREE.Mesh(geometry, material);

  box.userData.isCamera = true;
  box.userData.construction = camera;
  box.userData.isCameraPart = true;

  const group = new THREE.Group();
  group.add(box);
  group.position.set(0, 0, 0);

  group.userData.isCamera = true;
  group.userData.construction = camera;
  group.userData.isCameraSide = true;

  return [group, [box], undefined];
}
