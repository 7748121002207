import {Canvas, Circle, FabricObject, Image, Rect, util} from "fabric";
import {EraserBrush} from "./EraserBrush";
import {MaskBrush} from "./MaskBrush";

export function scaleObject(obj: FabricObject, scale: [x: number, y: number]) {
  const currentScaleX = obj.scaleX;
  const currentScaleY = obj.scaleY;
  const currentLeft = obj.left;
  const currentTop = obj.top;
  obj.scaleX = currentScaleX * scale[0];
  obj.scaleY = currentScaleY * scale[1];
  obj.left = currentLeft * scale[0];
  obj.top = currentTop * scale[1];
  obj.setCoords();
}

export function removeBrushes(canvas: Canvas) {
  if (canvas.freeDrawingBrush instanceof EraserBrush) {
    canvas.freeDrawingBrush.cleanUp();
  }
  if (canvas.freeDrawingBrush instanceof MaskBrush) {
    canvas.freeDrawingBrush.cleanUp();
  }
  canvas.freeDrawingBrush = undefined;
}

export function createLoadingCircle(canvas: Canvas): FabricObject[] {
  const overlay = new Rect({
    left: 0,
    top: 0,
    width: canvas.getWidth(),
    height: canvas.getHeight(),
    fill: 'rgba(255, 255, 255, 0.5)',
    selectable: false,
    evented: false,
  });
  canvas.add(overlay);

  const loadingCircle = new Circle({
    radius: 30,
    startAngle: 0,
    endAngle: 60,
    fill: 'rgba(0, 0, 0, 0)',
    stroke: '#3498db',
    strokeWidth: 5,
    originX: 'center',
    originY: 'center',
    left: canvas.getWidth() / 2,
    top: canvas.getHeight() / 2,
  });

  canvas.add(loadingCircle);

  function animateLoadingCircle() {
    if (loadingCircle) {
      loadingCircle.animate(
        {angle: loadingCircle.angle + 360},
        {
          onChange: canvas.renderAll.bind(canvas),
          duration: 1000,
          easing: util.ease.easeInOutCubic,
          onComplete: animateLoadingCircle,
        }
      );
    }
  }

  animateLoadingCircle();

  return [overlay, loadingCircle];
}

/**
 * Fades out a Fabric image object.
 *
 * @param canvas - The canvas to work on
 * @param image - The Fabric image to fade out.
 * If undefined, the callback will be called directly
 * @param duration - The duration of the fade-out effect in milliseconds.
 */
export function fadeOutImage(canvas: Canvas, image: Image | undefined, duration = 400): Promise<void> {
  return new Promise((resolve) => {
    if (image) {
      image.animate({opacity: 0}, {
        duration: duration,
        onChange: canvas.renderAll.bind(canvas),
        onComplete: () => {
          canvas.remove(image);
          resolve();
        }
      });
    } else {
      resolve();
    }
  });
}

/**
 * Fades out multiple Fabric image objects.
 *
 * @param canvas - The canvas to work on
 * @param images - The array of Fabric images to fade out.
 * @param duration - The duration of the fade-out effect in milliseconds.
 * @returns A promise that resolves when all animations are complete.
 */
export function fadeOutImages(canvas: Canvas, images: (Image | undefined)[], duration = 400): Promise<void> {
  const promises = images.map(image => fadeOutImage(canvas, image, duration));
  return Promise.all(promises).then(() => {
  });
}

/**
 * Fades in a Fabric image object.
 *
 * @param canvas - The canvas to work on
 * @param image - The Fabric image to fade in with the target index and opacity to reach.
 * @param duration - The duration of the fade-in effect in milliseconds.
 */
export function fadeInImage(canvas: Canvas, image: [Image, index: number, opacity: number], duration = 400): Promise<void> {
  image[0].set({opacity: 0});
  canvas.insertAt(image[1], image[0]);
  return new Promise((resolve) => {
    image[0].animate({opacity: image[2]}, {
      duration: duration,
      onChange: canvas.renderAll.bind(canvas),
      onComplete: () => {
        resolve();
      }
    });
  });
}

/**
 * Fades in multiple Fabric image objects.
 *
 * @param canvas - The canvas to work on
 * @param images - The array of Fabric image objects to fade in.
 * @param duration - The duration of the fade-in effect in milliseconds.
 * @returns A promise that resolves when all animations are complete.
 */
export function fadeInImages(canvas: Canvas, images: [Image, index: number, opacity: number][], duration = 400): Promise<void> {
  const promises = images.map(image => fadeInImage(canvas, image, duration));
  return Promise.all(promises).then(() => {
  });
}
